import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { Row, Col, Button, FormGroup, FormControl, InputGroup, 
    FormLabel, Alert, ListGroup, Badge, Form } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import TextareaAutosize from 'react-autosize-textarea'
import emailRegex from 'email-regex'
import { invitePlayersByEmail, sendInvites, getPendingInvites } from '@severed-links/common.severedlinks-reducers/groups'
import { createNotification } from '@severed-links/common.severedlinks-reducers/notifications'
import PlayerListItem from './PlayerListItem'
import { playerAvatarUrl } from '@severed-links/common.severedlinks-constants'
import * as s from './GroupPlayerInviter.scss'
import Confirm from '@severed-links/common.confirm'
import validator from 'validator'

const DeleteInvite = ({ index, onDelete }) => (
<span className={s.delete}>
    <Confirm title={'Delete Invitation'} 
        onConfirm={() => onDelete(index)}
        confirmText='delete invite'
        body={<div><p>Are you sure?</p></div>}
        variant='danger' size='sm' buttonIcon='times' />
</span>
)

const EmailLoadForm = ({ emailText, onChange, onKeyDown }) => (
<FormGroup className={s.formGroup}>
    <FormLabel>Paste emails here</FormLabel>
    <TextareaAutosize className='form-control' rows={2} 
        placeholder={'[Paste emails here...]'} value={emailText} 
        onKeyDown={e => onKeyDown(e)}
        onChange={e => onChange(e.target.value)} />
</FormGroup>
)

const AlreadyInGroupPlayerListItem = ({ player, index, onDelete }) => (
<PlayerListItem key={`group-player-invite-${player._id}-${index}`} 
    className={s.existingPlayerListItem} player={player} showRole={false} clickDisabled 
    rightDetail={<div className={s.rightDetail}>
    <Badge size='sm' bg='info'>Already in group</Badge>
    <DeleteInvite index={index} onDelete={onDelete} />
    </div>} />
)

const ExistingButNotInGroupPlayerListItem = ({ player, index, onDelete }) => (
<PlayerListItem key={`group-player-invite-${player._id}-${index}`} 
    className={s.existingPlayerListItem} player={player} showRole={false} clickDisabled 
    rightDetail={<div className={s.rightDetail}>
        <Badge size='sm' bg='primary'>Found user</Badge>
        <DeleteInvite index={index} onDelete={onDelete} />
    </div>} />
)

const NewPlayerFormControl = ({ fieldName, index, value, placeholder, onChange }) => (
    <FormControl className={s.textBox} 
        value={value || ''} placeholder={placeholder} 
        onChange={e => onChange(e, fieldName, index)} 
        isValid={!validator.isEmpty(value || '') && (fieldName !== 'emailAddress' || validator.isEmail(value || ''))} />
)

const NewPlayerListItem = ({ player, index, onChange, onDelete }) => (
<ListGroup.Item className={s.invitePlayerListItem + ' ' + s.newPlayer} 
    key={`group-player-invite-${player._id}-${index}`}
    style={{ backgroundImage: `url(${playerAvatarUrl(null, true)})` }}>
    <div className={s.inviteForm}>
        <Row className={s.row}>
            <Col sm={6} xs={12} className={s.formGroup}>
                <NewPlayerFormControl value={player.firstName} 
                    placeholder='[First name]' 
                    onChange={e => onChange(e, 'firstName', index)} />
            </Col>
            <Col sm={6} xs={12} className={s.formGroup}>
                <NewPlayerFormControl value={player.lastName} 
                    placeholder='[Last name]' 
                    onChange={e => onChange(e, 'lastName', index)} />
            </Col>
            <Col sm={12} xs={12} className={s.formGroup}>
                <NewPlayerFormControl value={player.emailAddress} 
                    placeholder='[Email address]' 
                    onChange={e => onChange(e, 'emailAddress', index)} />
            </Col>
        </Row>
    </div>
    <DeleteInvite index={index} onDelete={onDelete} />
</ListGroup.Item> 
)

const GroupPlayerInviter = ({ isGlobalAdminPath = false }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { group, groupId } = useOutletContext()
    const players = group.players || []

    const playerFilterLetter = useSelector(state => state.groups.playerFilterLetter)
    const [submittedEmailCount, setSubmittedEmailCount] = useState(0)
    const [emailText, setEmailText] = useState('')
    const [emails, setEmails] = useState([])
    const [invites, setInvites] = useState([])
    const [invalidEmails, setInvalidEmails] = useState([])
    const path = isGlobalAdminPath ? `/global-admin/groups/${(group.name || '').substring(0,1).toUpperCase()}/view/${groupId}/` : `/group/${groupId}/`

    const cancelAction = () => {
        setInvites([])
        setEmails([])
        setEmailText('')
        navigate(`${path}players${(playerFilterLetter ? `/filter/${playerFilterLetter}` : ``)}`)
    }

    const loadEmails = () => {
        const _emails = emailText.match(emailRegex())
        setEmails(_emails)
        dispatch(invitePlayersByEmail(groupId, _emails))
        .then(action => {
            setSubmittedEmailCount(action.payload.submittedEmailCount || 0)
            setInvites([
                ...(action.payload.inviteExistingPlayers || []).map(p => ({ ...p, type: 'existing', groupId })),
                ...(action.payload.newPlayers || []).map(p => ({ ...p, type: 'new', groupId })),
                ...(action.payload.alreadyInGroupPlayers || []).map(p => ({ ...p, type: 'inGroup', groupId })),
            ])
            setInvalidEmails([...(action.payload.invalidEmails || [])])
        })
    }

    const handleKeyPress = e => {
        const charCode = String.fromCharCode(e.keyCode).toLowerCase()
        if (e.key === 'Enter' || (e.metaKey && charCode === 'v')) {
            setTimeout(() => loadEmails(), 100)
        }
    }

     //test e-mails: https://gist.github.com/cjaoude/fd9910626629b53c4d25

     /*
email@example.com
firstname.lastname@example.com
email@subdomain.example.com
firstname+lastname@example.com
email@123.123.123.123
email@[123.123.123.123]
"email"@example.com
1234567890@example.com
email@example-one.com
_______@example.com
email@example.name
email@example.museum
email@example.co.jp
firstname-lastname@example.com
rick@vcl.com
tom@williamshouse.net
     */

    const handleFieldChange = (e, fieldName, index) => {
        const _invites = [...invites]
        _invites[index][fieldName] = e.target.value
        setInvites(_invites)
    }

    const deleteInvite = index => {
        const _invites = [...invites]
        _invites.splice(index, 1)
        if (_invites.length === 0) {
            setEmails([])
            setEmailText('')
            setInvites([])
        } else {
            setInvites(_invites)
        }
    }

    const doSendInvites = () => {
        dispatch(sendInvites(groupId, invites))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: "Send Group Invites", timeout: 4000 }))
            if (action.payload.messageType === 'success') {
                cancelAction()
            }
            dispatch(getPendingInvites(groupId))
        })
    }

    return (
        <div className={s.container}>
            <h3>Invite Players to Group</h3>
            {!emails || !emails.length ?
            <EmailLoadForm emailText={emailText}
                onKeyDown={handleKeyPress}
                onChange={_emailText => setEmailText(_emailText)} />
            :
            <Col md={6} sm={9} xs={12}>
                <ListGroup className={s.inviteList}>
                    <ListGroup.Item>
                        If there are any incomplete first or last names, please 
                        fill them in before sending your invitations.  It will make 
                        your invitations look more legitimate and friendly if your 
                        friends are addressed by name!
                    </ListGroup.Item>
                {invites && invites.map((p, index) => 
                    p.type === 'inGroup' ? 
                    <AlreadyInGroupPlayerListItem player={p} index={index}
                        onDelete={deleteInvite}
                        key={`group-player-invite-item-${p._id}-${index}`} />
                    : p.type === 'existing' ? 
                    <ExistingButNotInGroupPlayerListItem player={p} index={index}
                        onDelete={deleteInvite}
                        key={`group-player-invite-item-${p._id}-${index}`} />
                    : p.type === 'new' ? 
                    <NewPlayerListItem player={p} index={index} 
                        onChange={handleFieldChange} 
                        onDelete={deleteInvite}
                        key={`group-player-invite-item-${p._id}-${index}`} />
                    : null
                )}
                </ListGroup>
            </Col>
            }
            <FormGroup className={s.formGroup + ' ' + s.buttonsContainer}>
                <Button className={s.button} variant='light' onClick={() => cancelAction()}><FontAwesomeIcon name='times' /> cancel</Button>
                {!emails || emails.length === 0 ?
                <Button className={s.button} variant='primary' onClick={() => loadEmails()}><FontAwesomeIcon name='upload' /> load emails</Button>
                : invites && invites.length > 0 ?
                <Confirm title={'Send Group Invitations'} 
                    onConfirm={() => doSendInvites()}
                    confirmText='send invites'
                    body={<div><p>Invites ready to send?</p><ul>{invites.map((p, index) => <li key={`group-player-invite-confirm-${p._id}-${index}`}>{p.firstName || p.lastName ? `${p.firstName} ${p.lastName} (${p.emailAddress})` : `${p.emailAddress}`}</li>)}</ul></div>}
                    variant='primary' buttonIcon='envelope' buttonText='send invites'
                    buttonClassName={s.button}  />
                : null}
            </FormGroup>
        </div>
    )
}

export default GroupPlayerInviter